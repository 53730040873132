import { MailerSend, EmailParams, Sender } from "mailersend"

const emailClient = new MailerSend({
  apiKey: process.env.MAILER_SEND_API_KEY || "",
})

const sentFrom = new Sender("noreply@useintegral.com", "Integral Team")

export const defaultEmailParams = new EmailParams().setFrom(sentFrom).setReplyTo(sentFrom)

export default emailClient
